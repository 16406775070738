// WrapperComponent.js
import { h, render } from 'preact';

export const SidebarWrapper = ({ children, classNames }) => (
    <div className={`sidebar-wrapper ${classNames}`}>
        {children}
    </div>
);

export function SidebarHeading({ text }) {
    return (
        <h3 className="h6 font-sans mb-3">{text}</h3>
    );
}

export function SidebarSeeAllButton({ url }) {
    return (
        <a className="mt-4 table text-slight text-green-600 hover:text-green-700 hover:underline" href={url}>See all</a>
    );
}
